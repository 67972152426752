import axios from 'axios';

const api = axios.create({
  baseURL: 'https://messages-images.cyclic.app',
});

// api.defaults.headers[
//   'Authorization'
// ] = `Bearer c7fccbb9a81c3eadf45656fcbc41219a66deba6a970d79af0b71a06530941704`;

// api.interceptors.request.use(async (config) => {
//   const token =
//     'c7fccbb9a81c3eadf45656fcbc41219a66deba6a970d79af0b71a06530941704';

//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }

//   return config;
// });

export default api;
