import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdElectricCar } from 'react-icons/md';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Swal from 'sweetalert2';

import Input from '../../components/Input';
import Button from '../../components/Button';

import {
  Container,
  Header,
  Content,
  ContainerSearch,
  ContainerRow,
  ContainerFlex,
} from './styles';
import { colors } from '../../commonStyle';

import api from '../../services/api';

export default function Home() {
  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('16603350000182');
  const [data, setData] = useState({});

  async function loadTracking() {
    if (code?.length < 10) {
      return Swal.fire('Digite um código válido para fazer a busca.');
    }

    setLoading(true);

    try {
      const response = await api.get(`/consultaCNPJ/${code}/brcintra@site`);
      setData(response.data);
    } catch (err) {
      Swal.fire('Erro ao tentar buscar o CNPJ.');
      //alert('Erro ao tentar buscar o codigo.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <Header>
        <h1>Consulta CNPJ Grátis</h1>
      </Header>

      <Content>
        <ContainerSearch>
          <h3>Digite o número do CNPJ:</h3>
          <ContainerRow style={{ marginTop: 10 }}>
            <Input
              onPress={() => setCode('')}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <div style={{ width: '20%', marginLeft: 20 }}>
              <Button text="Buscar" loading={loading} onPress={loadTracking} />
            </div>
          </ContainerRow>
        </ContainerSearch>

        {loading && (
          <ContainerSearch>
            <Skeleton height={40} />
            <div style={{ marginBottom: 10 }} />
            <Skeleton height={22} />
            <Skeleton height={22} />
            <Skeleton height={22} />
            <Skeleton height={22} />
            <Skeleton height={22} />
          </ContainerSearch>
        )}

        {!loading && data?.cnpj && (
          <ContainerSearch style={{ marginTop: 20 }}>
            <h1 style={{ marginBottom: 10 }}>Situação</h1>
            <h3>Situação: {data?.situacao}</h3>
            <h3>Data Situação: {data?.data_situacao}</h3>
          </ContainerSearch>
        )}

        {!loading && data?.cnpj && (
          <ContainerSearch style={{ marginTop: 20 }}>
            <h1 style={{ marginBottom: 10 }}>Informações</h1>
            <h3>CNPJ: {data?.cnpj}</h3>
            <h3>Razão Social: {data?.nome}</h3>
            <h3>Nome Fantasia: {data?.fantasia}</h3>
            <h3>Tipo: {data?.tipo}</h3>
            <h3>Porte: {data?.porte}</h3>
            <h3>Natureza Jurídica: {data?.natureza_juridica}</h3>
            <h3>Data Abertura: {data?.abertura}</h3>
            <h3>Capital Social: {data?.capital_social}</h3>
          </ContainerSearch>
        )}

        {!loading && data?.cnpj && (
          <ContainerSearch style={{ marginTop: 20 }}>
            <h1 style={{ marginBottom: 10 }}>Contato</h1>
            <h3>Telefone: {data?.telefone}</h3>
            <h3>Email: {data?.email}</h3>
          </ContainerSearch>
        )}

        {!loading && data?.cnpj && (
          <ContainerSearch style={{ marginTop: 20 }}>
            <h1 style={{ marginBottom: 10 }}>Atividade Principal</h1>
            {data?.atividade_principal?.map((item) => (
              <div>
                <h3>
                  {item?.code} - {item?.text}
                </h3>
              </div>
            ))}
          </ContainerSearch>
        )}

        {!loading && data?.cnpj && (
          <ContainerSearch style={{ marginTop: 20 }}>
            <h1 style={{ marginBottom: 10 }}>Atividades Secundárias</h1>
            {data?.atividades_secundarias?.map((item) => (
              <div>
                <h3>
                  {item?.code} - {item?.text}
                </h3>
              </div>
            ))}
          </ContainerSearch>
        )}

        {!loading && data?.cnpj && (
          <ContainerSearch style={{ marginTop: 20 }}>
            <h1 style={{ marginBottom: 10 }}>QSA</h1>
            {data?.qsa?.map((item) => (
              <div>
                <h3>
                  {item?.qual} - {item?.nome}
                </h3>
              </div>
            ))}
          </ContainerSearch>
        )}

        {!loading && data?.cnpj && (
          <ContainerSearch style={{ marginTop: 20 }}>
            <h1 style={{ marginBottom: 10 }}>Localização</h1>
            <h3>CEP: {data?.cep}</h3>
            <h3>Logradouro: {data?.logradouro}</h3>
            <h3>Número: {data?.numero}</h3>
            <h3>Complemento: {data?.complemento}</h3>
            <h3>Bairro: {data?.bairro}</h3>
            <h3>
              Município/UF: {data?.municipio}/{data?.uf}
            </h3>
          </ContainerSearch>
        )}
      </Content>
    </Container>
  );
}
